import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import anime from 'animejs';
import styled from 'styled-components';
import { IconLoader } from '@components/icons';

const StyledLoader = styled.div`
  ${({ theme }) => theme.mixins.flexCenter};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--darkest-purple);
  z-index: 99;

  .logo-wrapper {
    width: max-content;
    transition: var(--transition);
    opacity: ${props => (props.isMounted ? 1 : 0)};
    svg {
      max-width: 100px;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      fill: none;
      user-select: none;
      #J {
        opacity: 0;
      }
    }
  }

  h4 {
    padding: 20px;
    text-align: center;
    color: var(--dark-slate);
  }
`;

const Loader = ({ finishLoading }) => {
  const [isMounted, setIsMounted] = useState(false);

  const animate = () => {
    const loader = anime.timeline({
      complete: () => finishLoading(),
    });

    loader
      .add({
        targets: '#logo path',
        delay: 300,
        duration: 1200,
        easing: 'easeInOutQuart',
        strokeDashoffset: [anime.setDashoffset, 0],
      })
      .add({
        targets: '#logo #J',
        duration: 700,
        easing: 'easeInOutQuart',
        opacity: 1,
      })
      .add({
        targets: '#logo',
        delay: 500,
        duration: 300,
        easing: 'easeInOutQuart',
        opacity: 0,
        scale: 0.1,
      })
      .add({
        targets: '.loader',
        duration: 200,
        easing: 'easeInOutQuart',
        opacity: 0,
        zIndex: -1,
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10);
    animate();
    return () => clearTimeout(timeout);
  }, []);

  const splash = [
    'Welcome to my lair!',
    'Hello World!',
    'Any computer is a laptop if you\'re brave enough!',
    'Don\'t look directly at the bugs!',
    'Don\'t worry, be happy!',
    'Engage!',
    'Finally complete!',
    '/give @a hugs 64',
    'Hampsterdance!',
    'Internet enabled!',
    'Nice to meet you!',
    'Not as cool as Spock!',
    'Aw man!',
    'Random splash!',
    'Technologic!',
    'The sky is the limit!',
    'Wake me if you\'re out there!',
    'I went in a cornfield today!',
    'You are like cinnamon!',
    'Featuring flying tanks!',
    'Thanks, Brittany!',
    'It\'s always a good time!',
    'You would not believe your eyes!',
    'I like cheese, do you?',
    'RIP Mr. Timn!',
    'I\'m decent, how are you?',
    'What does the fox say?',
    'Now includes video demonstrations!',
    'May the force be with you!',
    'Cheese curds.',
    ':D',
    'I can\'t stop refreshing the page!',
  ];

  return (
    <StyledLoader className="loader" isMounted={isMounted}>
      <Helmet bodyAttributes={{ class: `hidden` }} />

      <div className="logo-wrapper">
        <IconLoader />
        <h4>{splash[Math.floor(Math.random() * splash.length)]}</h4>
      </div>
    </StyledLoader>
  );
};

Loader.propTypes = {
  finishLoading: PropTypes.func.isRequired,
};

export default Loader;
