import React from 'react';

const IconCode = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256">
    <g fill="currentColor" strokeMiterlimit="10" strokeWidth="0">
      <path
        d="M90 56.176H0V9.247a5.426 5.426 0 015.42-5.42h79.16A5.426 5.426 0 0190 9.247v46.929zm-88-2h86V9.247a3.424 3.424 0 00-3.42-3.42H5.42A3.424 3.424 0 002 9.247v44.929z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
      <path
        d="M84.58 68.6H5.42A5.426 5.426 0 010 63.18v-9.004h90v9.004a5.427 5.427 0 01-5.42 5.42zM2 56.176v7.004a3.424 3.424 0 003.42 3.42h79.16A3.424 3.424 0 0088 63.18v-7.004H2z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
      <path
        d="M58.825 80.963h-27.65a1 1 0 01-.592-1.805c3.798-2.789 6.333-7.05 6.955-11.69a1 1 0 01.991-.867h12.94c.501 0 .925.37.991.867.622 4.641 3.158 8.901 6.956 11.69a.998.998 0 01-.591 1.805zm-24.938-2h22.227A19.499 19.499 0 0150.616 68.6H39.384a19.49 19.49 0 01-5.497 10.363z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
      <path
        d="M61.054 86.174H28.946c-1.988 0-3.605-1.617-3.605-3.605s1.617-3.605 3.605-3.605h32.108c1.988 0 3.605 1.617 3.605 3.605s-1.617 3.605-3.605 3.605zm-32.108-5.211c-.885 0-1.605.72-1.605 1.605s.72 1.605 1.605 1.605h32.108a1.606 1.606 0 000-3.21H28.946zM46.701 62.388h-3.402a1 1 0 110-2h3.402a1 1 0 110 2zM36.285 39.729c-3.492 0-4.498-3.108-5.307-5.605C30.15 31.567 29.55 30 27.715 30a1 1 0 010-2c1.835 0 2.436-1.567 3.264-4.124.809-2.498 1.815-5.605 5.307-5.605a1 1 0 010 2c-1.939 0-2.557 1.604-3.404 4.221-.511 1.579-1.103 3.405-2.36 4.508 1.257 1.103 1.849 2.929 2.36 4.508.847 2.617 1.465 4.221 3.404 4.221a1 1 0 11-.001 2zM53.715 39.729a1 1 0 110-2c1.938 0 2.557-1.604 3.404-4.222.511-1.578 1.103-3.405 2.36-4.507-1.258-1.103-1.85-2.929-2.36-4.507-.848-2.618-1.466-4.222-3.404-4.222a1 1 0 110-2c3.492 0 4.498 3.108 5.307 5.605C59.85 26.433 60.45 28 62.285 28a1 1 0 110 2c-1.835 0-2.436 1.567-3.264 4.124-.808 2.497-1.814 5.605-5.306 5.605zM41.069 42.052a1 1 0 01-.948-1.318l8.08-24.103a1.001 1.001 0 011.897.636l-8.08 24.103a1.004 1.004 0 01-.949.682z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
    </g>
  </svg>
);

export default IconCode;
