import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g id="J" transform="translate(11.000000, 5.000000)">
        <path
          fontFamily="Oxygen Mono"
          fontSize="40"
          style={{ isCustomFont: 'none', fontFileUrl: 'none', whiteSpace: 'pre' }}
          strokeWidth="0"
          d="M2.05 3.87v5.88h-5.88V3.87h5.88zm0-13.37v5.85h-5.88V-9.5h5.88zm11.93 22.07l-.02-2.95q4.4.48 5.45.48 1.06 0 1.84-.11 3.81-.63 3.81-5.06V-13.7H16.7v-2.86h12.01V3.91q0 4.59-2 6.78-2 2.19-7.16 2.19-1.82 0-5.57-.31z"
          transform="translate(21.98 48)"
          fill="currentColor"
        />
        <path
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 39, 0 
            L 0, 22 
            L 0, 67 
            L 39, 90 
            L 78, 68 
            L 78, 23 z"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
